import React from 'react';
import { Stack, Typography } from '@mui/material';
import Link from 'next/link';
import palette from 'components/common/style/color.module.scss';

type FooterMenusProps = {
    menuItems: {
        title: string;
        urls: {
            title: string;
            link: string;
        }[];
    };
};
const FooterMenus: React.FC<FooterMenusProps> = ({ menuItems: { title, urls } }) => {
    return (
        <Stack
            spacing={2}
            alignItems={{
                xs: 'center',
                md: 'flex-start',
            }}>
            <Typography
                variant='b1_bold'
                color='black'>
                {title}
            </Typography>
            {urls.map((item, index) => (
                <Typography
                    key={index}
                    variant='b2_regular'
                    sx={{
                        '& a': {
                            textDecoration: 'none',
                            color: palette.black,
                        },
                    }}>
                    <Link href={item.link}>{item.title}</Link>
                </Typography>
            ))}
        </Stack>
    );
};

export default FooterMenus;
