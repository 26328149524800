export enum Routes {
    Root = '/',
    Login = '/login',
    Dashboard = '/dashboard',
    Flight = '/',
    Hotel = '/hotels',
    Visa = '/visa',
    VisaSearchResults = '/visa/searchresults',
    Tours = '/tours',
    TravelersService = '/travel-pack',
    SafratYoutube = 'https://www.youtube.com/@safratiq',
    SafratLinkedin = 'https://www.linkedin.com/company/safrat-%D8%B3%D9%81%D8%B1%D8%A7%D8%AA/about/?viewAsMember=true',
    SafratInstagram = 'https://www.instagram.com/safrat.iq?igsh=MWswaGkxaGtxZmk0aQ==',
    SafratFacebook = 'https://www.facebook.com/safrat.iq',
    Services = '/[...slug]',
    SafratDebitCard = '/iran/debit-card',
    SafratSimCard = '/iran/sim-card',
    SafratInsurance = '/iran/insurance',
    SafratPickUp = '/iran//pick-up',
    SafratFlightTicketRules = 'https://safrat.me/blog/?s=%D8%B4%D8%B1%D8%A7&jet_ajax_search_settings=%7B%22search_source%22%3A%22post%22%2C%22results_order_by%22%3A%22relevance%22%2C%22results_order%22%3A%22asc%22%7D&post_type=post',
    SafratHotelBookingRules = 'https://safrat.me/blog/hotel-reservation-laws-and-regulations/',
    SafratVisaInssuranceRules = 'https://safrat.me/blog/laws-and-visa-regulations/',

    // Visit
    Blog = '/blog/',
    Diaries = '/blog/iran-diaries/',
    VisitYoutube = 'https://www.youtube.com/@visitouriran',
    VisitTwitter = 'https://twitter.com/VisitOurIran/',
    VisitInstagram = 'https://www.instagram.com/visitouriran/',
    VisitFacebook = 'https://www.facebook.com/visitouriran/',
    HomeDiariesVideoIframe = 'https://www.youtube.com/embed/78hwCn1cRaM',
    ServiceIranVisa = '/iran-visa',
    ServiceInsurance = '/iran-travel-insurance',
    ServiceDebitCard = '/iran-travel-debit-card',
    ServiceTransfer = '/iran-transfer',
    ServiceSimCard = '/sim-card',
    ServiceEssentialTravelersPackage = '/essential-travelers-package',
    TravelStyleInDepthCultural = '/tours/iran-in-depth-cultural-tours',
    TravelStylePilgrimage = '/tours/iran-ziyarat-tours',
    TravelStyleAdventureNature = '/tours/iran-adventure-and-nature-tours',
    TravelStyleNomads = '/tours/iran-nomad-tours',
    TravelStyleWildlife = '/tours/iran-wildlife-tours',
    TravelStyleSilkRoad = '/tours/silk-road-tours',
    TravelStyleIranMedical = '/tours/iran-medical-tours',
    TravelStyleSkiing = '/tours/iran-ski-tours',
    TravelStyleHikingTrekking = '/tours/iran-hiking-and-trekking-tours',
    TravelStyleFoodCulinary = '/tours/iran-food-and-culinary-tours',
    TripadvisorAttractionReview = 'https://www.tripadvisor.com/Attraction_Review-g293999-d17313801-Reviews-Visit_Our_Iran-Tehran_Tehran_Province.html',
    AboutUs = '/blog/about-us/',
    ContactUs = '/blog/about-us/',
    DashboardTrackOrder = '/dashboard/track-order',
    OrederConfirm = '/order/confirm',
    VisitFlight = '/flight',
    VisitHotel = '/hotel',
}
