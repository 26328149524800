import { Box, Grid, Stack, Typography } from '@mui/material';
import FooterMenus from './footer-menus';
import FooterContacts from './footer-contacts';
import React from 'react';
import { useLanguage } from '@travel-platform/commons/src/components/common/language/language';
import NextImage from '@travel-platform/commons/src/components/common/next-image';
import { FooterInfo } from 'constants/footer-data';

const Footer: React.FC = () => {
    const languageJson = useLanguage();
    const { footerData } = FooterInfo();

    const { links } = footerData;
    return (
        <Stack
            spacing={1}
            component='footer'
            justifyContent='space-between'
            py={10}>
            <Box>
                <Box className='container'>
                    <Grid
                        container
                        rowGap={{
                            xs: 8,
                            md: 0,
                        }}
                        columnSpacing={{
                            md: 3,
                        }}>
                        <Grid
                            item
                            xs={12}
                            md={4.5}>
                            <Stack
                                alignItems='center'
                                justifyContent='space-between'
                                spacing={2}>
                                <NextImage
                                    width={164}
                                    height={84}
                                    src={footerData.imageSrc}
                                    loading='lazy'
                                    alt='visit logo'
                                />
                                <Typography
                                    variant='b2_regular'
                                    textAlign={{
                                        xs: 'center',
                                        md: 'left',
                                    }}>
                                    {footerData.slogan}
                                </Typography>
                            </Stack>
                        </Grid>
                        {links.map(linksList => (
                            <Grid
                                key={linksList.title}
                                item
                                xs={6}
                                md={1.5}>
                                <FooterMenus menuItems={linksList} />
                            </Grid>
                        ))}
                        <Grid
                            item
                            xs={12}
                            md={4}>
                            <FooterContacts {...footerData} />
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Stack>
    );
};
export default Footer;
