import { useLanguage } from '@travel-platform/commons/src/components/common/language/language';
import { Routes } from 'routes';
import { PROFILE_PROJECT } from '@travel-platform/commons/src/components/common/constant/constant';

const Base_URL = process.env.NEXT_PUBLIC_HOME_PAGE_LANDING_URL;
export const FooterInfo = () => {
    // Hooks
    const languageJson = useLanguage();
    const safratFooterTranslation = languageJson.static_content.landing.footer;

    const footers = {
        footerInfo_PRODUCTION: {
            imageSrc: '/logo-PRODUCTION-footer.svg',
            slogan: safratFooterTranslation.slogan,
            mobile: ' +964 785 404 1530',
            phone: '',
            email: 'info@safrat.me',
            addresses: [safratFooterTranslation.address],
            socialMedia: [
                { icon: 'icon-instagram', url: Routes.SafratInstagram, label: 'Safrat Page' },
                {
                    icon: 'icon-whatsapp',
                    url: `https://wa.me/+${process.env.NEXT_PUBLIC_SAFRAT_WHATSAPP_NUMBER}`,
                    label: 'Safrat Whatsapp',
                },
                { icon: 'icon-youtube', url: Routes.SafratYoutube, label: 'Safrat Channel' },
                { icon: 'icon-facebook', url: Routes.SafratFacebook, label: 'Safrat Profile' },
            ],
            links: [
                {
                    title: safratFooterTranslation.important_links,
                    urls: [
                        { title: safratFooterTranslation.flight_ticket_rules, link: Routes.SafratFlightTicketRules },
                        { title: safratFooterTranslation.hotel_booking_rules, link: Routes.SafratHotelBookingRules },
                        { title: safratFooterTranslation.visa_inssurance_rules, link: Routes.SafratVisaInssuranceRules },
                    ],
                },
                {
                    title: languageJson.common.services,
                    urls: [
                        { title: languageJson.common.flight, link: Routes.Flight },
                        { title: languageJson.common.hotel, link: Routes.Hotel },
                        { title: languageJson.common.debit_card, link: Routes.SafratDebitCard },
                        { title: languageJson.common.sim_card, link: Routes.SafratSimCard },
                        { title: languageJson.common.insurance, link: Routes.SafratInsurance },
                        { title: languageJson.common.airport_pickup, link: Routes.SafratPickUp },
                    ],
                },
            ],
        },
        footerInfo_BETA: {
            imageSrc: '/logo-PRODUCTION-footer.svg',
            slogan: safratFooterTranslation.slogan,
            mobile: ' +964 785 404 1530',
            phone: '',
            email: 'info@safrat.me',
            addresses: [safratFooterTranslation.address],
            socialMedia: [
                { icon: 'icon-instagram', url: Routes.SafratInstagram, label: 'Safrat Page' },
                {
                    icon: 'icon-whatsapp',
                    url: `https://wa.me/+${process.env.NEXT_PUBLIC_SAFRAT_WHATSAPP_NUMBER}`,
                    label: 'Safrat Whatsapp',
                },
                { icon: 'icon-youtube', url: Routes.VisitYoutube, label: 'Safrat Channel' },
                { icon: 'icon-facebook', url: Routes.VisitFacebook, label: 'Safrat Profile' },
            ],
            links: [
                {
                    title: safratFooterTranslation.important_links,
                    urls: [
                        { title: safratFooterTranslation.flight_ticket_rules, link: Routes.SafratFlightTicketRules },
                        { title: safratFooterTranslation.hotel_booking_rules, link: Routes.SafratHotelBookingRules },
                        { title: safratFooterTranslation.visa_inssurance_rules, link: Routes.SafratVisaInssuranceRules },
                    ],
                },
                {
                    title: languageJson.common.services,
                    urls: [
                        { title: languageJson.common.flight, link: Routes.Flight },
                        { title: languageJson.common.hotel, link: Routes.Hotel },
                        { title: languageJson.common.debit_card, link: Routes.SafratDebitCard },
                        { title: languageJson.common.sim_card, link: Routes.SafratSimCard },
                        { title: languageJson.common.insurance, link: Routes.SafratInsurance },
                        { title: languageJson.common.airport_pickup, link: Routes.SafratPickUp },
                    ],
                },
            ],
        },
        footerInfo_VISITOURIRAN: {
            imageSrc: `${process.env.NEXT_PUBLIC_BASE_PATH || ''}/logo-${process.env.NEXT_PUBLIC_PROJECT_PROFILE}-footer.svg`,
            slogan: safratFooterTranslation.visit_desc,
            mobile: '+98 92 00 98 00 92',
            phone: '+98 21 88 55 34 94',
            email: 'info@visitouriran.com',
            addresses: [safratFooterTranslation.address, safratFooterTranslation.visit_office_address],
            socialMedia: [
                { icon: 'icon-instagram', url: Routes.VisitInstagram, label: 'Visit Our Iran Page' },
                { icon: 'icon-twitter', url: Routes.VisitTwitter, label: 'Visit Our Iran Profile' },
                {
                    icon: 'icon-whatsapp',
                    url: `https://wa.me/+${process.env.NEXT_PUBLIC_WHATSAPP_VISIT}?text=Hi,%20Can%20you%20help%20me%20?`,
                    label: 'Visit Our Iran Whatsapp',
                },
                { icon: 'icon-youtube', url: Routes.VisitYoutube, label: 'Visit Our Iran Channel' },
                { icon: 'icon-facebook', url: Routes.VisitFacebook, label: 'Visit Our Iran Profile' },
            ],
            links: [
                {
                    title: languageJson.static_content.landing.footer.important_links,
                    urls: [
                        { title: languageJson.common.flight, link: Routes.Flight },
                        { title: languageJson.common.hotel, link: Routes.Hotel },
                        {
                            title: languageJson.static_content.landing.footer.about_us,
                            link: Base_URL + Routes.AboutUs,
                        },
                        {
                            title: languageJson.static_content.landing.footer.contact_us,
                            link: Base_URL + Routes.ContactUs,
                        },
                        { title: languageJson.common.blog, link: Base_URL + Routes.Blog },
                        { title: languageJson.common.diaries, link: Base_URL + Routes.Diaries },
                    ],
                },
                {
                    title: languageJson.common.services,
                    urls: [
                        { title: languageJson.common.iran_visa, link: Base_URL + '/iran-visa' },
                        { title: languageJson.common.insurance, link: Base_URL + '/iran-travel-insurance' },
                        { title: languageJson.common.debit_card, link: Base_URL + '/iran-travel-debit-card' },
                        { title: languageJson.common.transfer, link: Base_URL + '/iran-transfer' },
                        { title: languageJson.common.sim_card, link: Base_URL + '/sim-card' },
                    ],
                },
            ],
        },
    };

    return { footerData: footers[`footerInfo_${PROFILE_PROJECT}` as keyof typeof footers] };
};
