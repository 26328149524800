import React from 'react';
import { Box, Grid, Stack, type SxProps, type Theme, Typography } from '@mui/material';
import Link from 'next/link';
import palette from 'components/common/style/color.module.scss';
import { Routes } from 'routes';
import { useLanguage } from '@travel-platform/commons/src/components/common/language/language';

type SocialsItem = {
    icon: string;
    url: string | Routes;
    label: string;
};
type FooterContactsProps = {
    mobile: string;
    phone: string;
    email: string;
    addresses: string[];
    socialMedia: SocialsItem[];
};

// MUI SX
const contactKey: SxProps<Theme> = {
    typography: {
        xs: 'c1_regular',
        md: 'b1_regular',
    },
};
const contactValue: SxProps<Theme> = {
    typography: {
        xs: 'b2_regular',
        md: 'b1_regular',
    },
};
const FooterContacts: React.FC<FooterContactsProps> = ({ socialMedia, addresses, email, phone, mobile }) => {
    const languageJson = useLanguage();
    return (
        <Stack
            p={2}
            spacing={2}>
            <Stack spacing={2}>
                <Stack
                    direction='row'
                    alignItems='center'
                    width='100%'
                    spacing={2}>
                    <Box
                        component='span'
                        className='icon-call-left'
                        fontSize={28}></Box>
                    <Stack
                        width='100%'
                        spacing={1}>
                        {phone && (
                            <Grid container>
                                <Grid
                                    item
                                    xs={4}>
                                    <Typography sx={contactKey}>{languageJson.common.telephone}:</Typography>
                                </Grid>

                                <Grid
                                    item
                                    display='flex'
                                    justifyContent='end'
                                    xs={8}>
                                    <Typography
                                        component='span'
                                        sx={contactValue}
                                        style={{ direction: 'ltr' }}>
                                        {phone}
                                    </Typography>
                                </Grid>
                            </Grid>
                        )}
                        {mobile && (
                            <Grid container>
                                <Grid
                                    item
                                    xs={4}>
                                    <Typography sx={contactKey}>{languageJson.common.mobile}:</Typography>
                                </Grid>

                                <Grid
                                    item
                                    display='flex'
                                    justifyContent='end'
                                    xs={8}>
                                    <Typography
                                        component='span'
                                        sx={contactValue}
                                        style={{ direction: 'ltr' }}>
                                        {mobile}
                                    </Typography>
                                </Grid>
                            </Grid>
                        )}
                    </Stack>
                </Stack>
                <Stack
                    direction='row'
                    alignItems='center'
                    justifyContent='space-between'
                    width='100%'
                    spacing={2}>
                    <Box
                        className='icon-sms'
                        fontSize={28}></Box>
                    <Typography
                        sx={contactValue}
                        width='100%'
                        style={{ direction: 'ltr' }}>
                        {email}
                    </Typography>
                </Stack>
                <Stack
                    direction='column'
                    spacing={2}>
                    {addresses.map((address, i) => (
                        <Stack
                            key={i}
                            direction='row'
                            width='100%'
                            spacing={2}>
                            <Box
                                component='span'
                                className='icon-location'
                                fontSize={28}></Box>
                            <Typography sx={contactValue}>{address}</Typography>
                        </Stack>
                    ))}
                </Stack>
            </Stack>

            <Stack
                direction='row'
                flexWrap='wrap'
                mt={6}
                gap={2}
                justifyContent={{
                    xs: 'center',
                    md: 'space-between',
                }}>
                {socialMedia.map((item, index) => (
                    <Link
                        key={index}
                        href={item.url}>
                        <Box
                            p={1.5}
                            borderRadius={0.5}
                            sx={{
                                backgroundColor: {
                                    xs: palette.white,
                                    md: 'transparent',
                                },
                            }}>
                            <Box
                                fontSize={24}
                                color={{
                                    xs: palette.primary,
                                    md: palette.gray_darker_60,
                                }}
                                className={item.icon}></Box>
                        </Box>
                    </Link>
                ))}
            </Stack>
        </Stack>
    );
};
export default FooterContacts;
