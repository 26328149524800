import React from 'react';
import { ChildrenItemType } from '@travel-platform/commons/src/components/common/header/header.types';
import { useLanguage } from '@travel-platform/commons/src/components/common/language/language';
import { useRouter } from 'next/router';
import { Routes } from 'routes';
import { apiCmsGetCountries } from '@travel-platform/commons/src/services';
import { PROFILE_PROJECT } from '@travel-platform/commons/src/components/common/constant/constant';
import { isIOS } from 'react-device-detect';

const Base_URL = process.env.NEXT_PUBLIC_HOME_PAGE_LANDING_URL;
export const HeaderItems = () => {
    // States
    const [countries, setCountries] = React.useState<ChildrenItemType[]>([]);

    // Hooks
    const languageJson = useLanguage();
    const router = useRouter();

    const getCountries = React.useCallback(async () => {
        try {
            const {
                data: { data },
            } = await apiCmsGetCountries(router?.locale || null);
            const filteredDataOnIran = isIOS ? data.filter(item => !item.url.includes('iran')) : data;

            setCountries(
                filteredDataOnIran.map(
                    ({ title, ...item }): ChildrenItemType => ({
                        title,
                        subTitle: item?.subTitle || '',
                        recommended: item?.recommended || false,
                        icon: item?.icon || '',
                        url: item?.url || '',
                    })
                )
            );
        } catch (err: any) {
            console.log(err);
        }
    }, []);

    // Effect
    React.useEffect(() => {
        getCountries();
    }, []);
    const menus = {
        headerMenu_PRODUCTION: [
            {
                title: languageJson.common.flight,
                url: Routes.Flight,
            },
            {
                title: languageJson.common.hotel,
                url: Routes.Hotel,
            },
            // {
            //     title: languageJson.common.tour,
            //     url: Routes.Tours,
            // },
            // {
            //     title: languageJson.common.visa,
            //     url: Routes.Visa,
            // },
            {
                title: languageJson.common.destination_pure,
                subMenu: {
                    id: 1,
                    description: '',
                    childrenItem: countries || [],
                },
            },
            {
                title: languageJson.common.blog,
                url: process.env.NEXT_PUBLIC_BLOG_API_URL,
            },
        ],
        headerMenu_BETA: [
            {
                title: languageJson.common.flight,
                url: Routes.Flight,
            },
            {
                title: languageJson.common.hotel,
                url: Routes.Hotel,
            },
            // {
            //     title: languageJson.common.tour,
            //     url: Routes.Tours,
            // },
            {
                title: languageJson.common.visa,
                url: Routes.Visa,
            },
            {
                title: languageJson.common.destination_pure,
                subMenu: {
                    id: 1,
                    description: '',
                    childrenItem: countries || [],
                },
            },
            {
                title: languageJson.common.blog,
                url: process.env.NEXT_PUBLIC_BLOG_API_URL,
            },
        ],
        headerMenu_VISITOURIRAN: [
            {
                title: languageJson.common.home,
                url: Base_URL + Routes.Root,
            },
            {
                title: languageJson.common.flight,
                url: Base_URL + Routes.VisitFlight,
            },
            {
                title: languageJson.common.hotel,
                url: Base_URL + Routes.VisitHotel,
            },
            {
                title: languageJson.common.services,
                subMenu: {
                    id: 1,
                    description: languageJson.common.all_services_you_need,
                    childrenItem: [
                        {
                            title: languageJson.common.travellers_package,
                            subTitle: languageJson.common.all_in_one,
                            recommended: true,
                            icon: 'icon-bag-2',
                            url: Base_URL + Routes.TravelersService,
                        },
                        {
                            title: languageJson.common.iran_visa,
                            icon: 'icon-global',
                            url: Base_URL + Routes.ServiceIranVisa,
                        },
                        {
                            title: languageJson.common.insurance,
                            icon: 'icon-umbrella',
                            url: Base_URL + Routes.ServiceInsurance,
                        },
                        {
                            title: languageJson.common.debit_card,
                            icon: 'icon-card',
                            url: Base_URL + Routes.ServiceDebitCard,
                        },
                        {
                            title: languageJson.common.transfer,
                            icon: 'icon-arrow-swap-bold',
                            url: Base_URL + Routes.ServiceTransfer,
                        },
                        {
                            title: languageJson.common.sim_card,
                            icon: 'icon-simcard',
                            url: Base_URL + Routes.ServiceSimCard,
                        },
                    ],
                },
            },
            {
                title: languageJson.common.travel_style,
                subMenu: {
                    id: 2,
                    description: languageJson.common.travel_style_for_everyone,
                    childrenItem: [
                        {
                            title: languageJson.common.in_depth_cultural,
                            url: Base_URL + Routes.TravelStyleInDepthCultural,
                        },
                        {
                            title: languageJson.common.pilgrimage,
                            url: Base_URL + Routes.TravelStylePilgrimage,
                        },
                        {
                            title: languageJson.common.adventure_and_nature,
                            url: Base_URL + Routes.TravelStyleAdventureNature,
                        },
                        {
                            title: languageJson.common.nomads,
                            url: Base_URL + Routes.TravelStyleNomads,
                        },
                        {
                            title: languageJson.common.wildlife,
                            url: Base_URL + Routes.TravelStyleWildlife,
                        },
                        {
                            title: languageJson.common.silk_road,
                            url: Base_URL + Routes.TravelStyleSilkRoad,
                        },
                        {
                            title: languageJson.common.skiing,
                            url: Base_URL + Routes.TravelStyleSkiing,
                        },
                        {
                            title: languageJson.common.hiking_and_trekking,
                            url: Base_URL + Routes.TravelStyleHikingTrekking,
                        },
                        {
                            title: languageJson.common.food_and_culinary,
                            url: Base_URL + Routes.TravelStyleFoodCulinary,
                        },
                    ],
                },
            },
            {
                title: languageJson.common.diaries,
                url: Base_URL + Routes.Diaries,
            },
            {
                title: languageJson.common.blog,
                url: Base_URL + Routes.Blog,
            },
        ],
    };
    const languages = {
        languagesMenu_PRODUCTION: [
            {
                value: 'ar',
                title: 'العربیه',
            },
            {
                value: 'en',
                title: 'English',
            },
        ],
        languagesMenu_BETA: [
            {
                value: 'ar',
                title: 'العربیه',
            },
            {
                value: 'en',
                title: 'English',
            },
        ],
        languagesMenu_VISITOURIRAN: [
            {
                value: 'en',
                title: 'English',
            },
            {
                value: 'ar',
                title: 'العربیه',
            },
        ],
    };
    return { headerMenuList: menus[`headerMenu_${PROFILE_PROJECT}` as keyof typeof menus], languages: languages[`languagesMenu_${PROFILE_PROJECT}` as keyof typeof languages] };
};
